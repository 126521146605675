import React from "react"
import Moment from "react-moment"
// import moment from "moment"
// import "moment/locale/de"
import { Link } from "gatsby"
import { RichText } from "prismic-reactjs"
import styled from "@emotion/styled"
import colors from "styles/colors"
import PropTypes from "prop-types"

const PostCardContainer = styled(Link)`
  border: 1px solid ${colors.grey200};
  text-decoration: none;
  color: currentColor;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0px 9px 24px rgba(0, 0, 0, 0.06);
  transition: all 150ms ease-in-out;
  &:hover {
    box-shadow: 0px 9px 24px rgba(0, 0, 0, 0.1);
    transition: all 150ms ease-in-out;
    cursor: pointer;

    .CardHeader{
      background-image: linear-gradient(to top, rgba(255,255,255,0.1), rgba(255,255,255,0.2)), url('${props =>
        props.backgroundImageUrl}');
      
    }
    .PostCardAction {
      color: ${colors.blue500};
      transition: all 400ms ease-in-out;

      span {
        transform: translateX(0px);
        opacity: 1;
        transition: transform 150ms ease-in-out;
      }
    }
  }
`

const CardHeader = styled("div")`
  padding: 3em 2.5em 2.25em 2.5em;
  background-image: linear-gradient(to top, rgba(255,255,255,0.3), rgba(255,255,255,0.5)), url('${props =>
    props.backgroundImageUrl}');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  height: 160px;
  transition: background-image 150ms ease-in-out;
`
const CardFooter = styled("div")`
  padding: 0.25em 2.5em 1.75em 2.5em;
`
const CardBody = styled("div")`
  padding: 1.25em 2.5em 1em 2.5em;
`

const PostCategory = styled("h6")`
  font-weight: 600;
  padding: 1em 0 0em 0;
  color: ${colors.grey600};
`

const PostTitle = styled("h3")`
  padding: 0em 0 0.25em 0;
  font-size: 1em;
  color: #414042;
`

const PostMetas = styled("div")`
  display: flex;
  align-items: center;
  margin-top: 1.5em;
  justify-content: space-between;
  font-size: 0.85em;
  color: ${colors.grey600};
`

const PostAuthor = styled("div")`
  margin: 0;
  font-size: 11px;
`

const PostDate = styled("div")`
  margin: 0;
  font-size: 11px;
`

const PostDescription = styled("div")`
  padding: 0.25em 0em 0.25em 0em;

  p:last-of-type {
    margin: 0;
  }
`

const PostCardAction = styled("div")`
  font-weight: 600;
  text-decoration: none;
  color: currentColor;
  transition: all 150ms ease-in-out;

  span {
    margin-left: 1em;
    transform: translateX(-8px);
    display: inline-block;
    transition: transform 400ms ease-in-out;
  }
`

const legiblyTruncateDescription = description => {
  const maxCharLength = 100
  if (description.length <= maxCharLength) return description
  const shortendDescription = description.substr(
    0,
    description.lastIndexOf(" ", maxCharLength)
  )
  return shortendDescription
}

const PostCard = ({
  author,
  category,
  date,
  image,
  title,
  locale,
  description,
  uid,
}) => {
  // let localeMap = locale == "en" ? "en-gb" : locale
  // console.log(localeMap)
  // moment().locale(localeMap)
  return (
    <PostCardContainer
      className="BlogPostCard"
      to={`/${locale}/${uid}`}
      backgroundImageUrl={image.url}
    >
      <div>
        <CardHeader
          backgroundImageUrl={image.url}
          className="CardHeader"
        ></CardHeader>
        <CardBody>
          <PostCategory>{category[0].text}</PostCategory>
          <PostTitle>{title[0].text}</PostTitle>
          <PostDescription>
            {legiblyTruncateDescription(description[0].text)}...
          </PostDescription>
        </CardBody>
      </div>
      <CardFooter>
        <PostCardAction className="PostCardAction">
          {locale == "de" ? "Mehr Lesen" : "Read more"} <span>&#8594;</span>
        </PostCardAction>
        <PostMetas>
          <PostAuthor>{author}</PostAuthor>
          <PostDate>
            <Moment format="MMM D, YYYY" locale="de">
              {date}
            </Moment>
          </PostDate>
        </PostMetas>
      </CardFooter>
    </PostCardContainer>
  )
}

export default PostCard

PostCard.propTypes = {
  author: PropTypes.string.isRequired,
  category: PropTypes.array.isRequired,
  image: PropTypes.array.isRequired,
  date: PropTypes.string.isRequired,
  title: PropTypes.array.isRequired,
  description: PropTypes.array.isRequired,
  uid: PropTypes.string.isRequired,
  locale: PropTypes.string.isRequired,
}
